
/* adapted from https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/ */
import React, { createContext, useContext, useEffect, useState } from 'react';

const viewportContext = createContext({});

const ViewportProvider = ({ children }) => {
  // This is the exact same logic that we previously had in our hook

  const [appWidth, setWidth] = useState(window.innerWidth);
  const [appHeight, setHeight] = useState(window.innerHeight);
  const [screenSize, setScreenSize ] = useState(window.innerWidth > 1536 ? "desktop" : window.innerWidth > 768 ? "tablet" : "mobile");

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setScreenSize(window.innerWidth > 1536 ? "desktop" : window.innerWidth > 768 ? "tablet" : "mobile");
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return (
    <viewportContext.Provider value={{ appWidth, appHeight, screenSize }}>
      {children}
    </viewportContext.Provider>
  );
};

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
const useViewport = () => {
  /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
  const { appWidth, appHeight, screenSize } = useContext(viewportContext);
  const ratio = appWidth/appHeight;
  return { appWidth, appHeight, screenSize, ratio };
}

export { ViewportProvider, useViewport };