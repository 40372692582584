import { useEffect, useRef, useReducer } from 'react';
import _isEqual from "lodash/isEqual";


const useWpApiData = ({postSlug, baseURL}) => {
    
    const cache = useRef({});  // we cache the results so we don't need to make the same API call twice
    const initialState = {
        status: "idle",
        error: null,
        data: null,
    };

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "FETCHING":
                if (state.status === "fetching") return state;
                return { ...initialState, status: "fetching" };
            case "FETCHED":
                if (state.status === "fetched" && _isEqual(state.data, action.payload)) return state;
                return { ...initialState, status: "fetched", data: action.payload };
            case "FETCH_ERROR":
                return { ...initialState, status: "error", error: action.payload };
            case "RESET":
                return initialState;
            default:
                return state;
        }
    }, initialState);

    useEffect(() => {
        if (!postSlug) {
            dispatch({ type: "RESET" });
            return;
        }
        const url = baseURL + "/wp-json/wp/v2/toolbox?slug=" + postSlug;
        const fetchData = async () => {


            // check if the data is already available
            const dataCache = (cache.current && cache.current[postSlug]) 
                ? cache.current[postSlug]
                : null;

            if ( dataCache ) {
                let currentlyFetching = dataCache === 'fetching';
                dispatch({ type: currentlyFetching ? "FETCHING" : "FETCHED", payload: dataCache });
            
            } else {
                cache.current[postSlug] = "fetching";
                dispatch({ type: "FETCHING" });
                try {
                    const response = await fetch(url);
                    const posts = await response.json();
                    const postData = posts[0];

                    // add the new recods to the cache
                    cache.current[postSlug]= postData;
                    dispatch({ type: "FETCHED", payload: postData });
                } catch (error) {
                    dispatch({ type: "FETCH_ERROR", payload: error.message });
                    throw error;
                }
            }
        };
        fetchData();
    }, [postSlug, baseURL]);

    return state;
};


export default useWpApiData;



