import React, { useContext } from "react";
import appContext from "../context/appContext";
import SplashCat from "./SplashCat";
import { toolboxTopics, toolboxCategoriesByName } from "../assets/toolboxInfo";
import CatDescription from "./CatDescription";
import ToolList from "./ToolList";
import ToolListIcon from "./ToolListIcon";

const CatOverview = () => {
    const { selectedCat } = useContext(appContext);

    if (selectedCat == null) {
        return <SplashCat />;
    } else {
        const categoryProps = toolboxCategoriesByName[selectedCat.name]

        return (
            <CatDescription header={<ToolListIcon input={toolboxTopics} color={categoryProps.color} icon={categoryProps.image} cat={selectedCat.name} />}>
                {selectedCat.description}
                <ToolList input={toolboxTopics} cat={selectedCat.name} color={categoryProps.color} />
            </CatDescription>
        );
    }
};
export default CatOverview;
