import { Box, Text, Heading, Anchor } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";

const Sidebar = ({ post, selectedPostMeta }) => {
    return (
        <>
            <Heading level="3" margin={{ bottom: "xsmall" }}>
                Resources
            </Heading>
            <Box gap="xsmall">
                {post.acf.resources.map((item, i) => (
                    <Box key={"resourceListItem-" + i}>
                        <Anchor
                            target="_blank"
                            icon={<FontAwesomeIcon icon={faExternalLink} />}
                            label={
                                <Box>
                                    <Box>
                                        <Text weight={"bold"} color="brand">
                                            {item.resource_label}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text size="xsmall" weight={"normal"}>
                                            {item.resource_provider}
                                        </Text>
                                    </Box>
                                </Box>
                            }
                            href={item.resource_url}
                            title={item.resource_label}
                            color={selectedPostMeta.color}
                        />
                    </Box>
                ))}
            </Box>
        </>
    );
};
export default Sidebar;
