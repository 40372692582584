import { Box } from "grommet";
import { useViewport } from "../shared/components/ReactContext_Viewport";

    
const CatDescription = ({ color = "white", header = "", children }) => {
    const { screenSize } = useViewport();
    return (
        <Box
            width={screenSize !== "mobile" ? { min: "medium", width: "fit-content", max: "large" } : null}
            margin={screenSize != "mobile" ? "medium" : {vertical: "medium", right: "medium"}}
            height={{ min: "fit-content" }}
        >
            <Box>{header}</Box>
            <Box
                background={{ color: color, opacity: "strong" }}
                margin={{ top: "-1em", left: "medium" }}
                pad={{ horizontal: "medium", top: screenSize !== "mobile" ? "medium" : "large", bottom: "medium" }}
                round="xxsmall"
                elevation="small"
                animation={{ type: screenSize !== "mobile" ? "fadeIn" : null }}
                style={{ zIndex: screenSize != "mobile" ? "2" : "inherit" }}
            >
                {children}
            </Box>
        </Box>
    );
};
export default CatDescription;