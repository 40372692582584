import { Box, Text } from "grommet";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { useViewport } from "../shared/components/ReactContext_Viewport";

const ToolButton = ({ title, color, slug }) => {
  const { screenSize } = useViewport();
  const navigate = useNavigate();
  let match = useMatch({
    path: "/" + slug,
    exact: true,
  });

  return (
    <Box
      hoverIndicator={{ background: { color: color, opacity: "weak" } }}
      fill="horizontal"
      className={"toolbox-nav"}
      background={match ? { color: color, opacity: "medium" } : ""}
      color={color}
      pad={
        screenSize !== "mobile"
          ? { horizontal: "small", vertical: "xxsmall" }
          : { horizontal: "medium", vertical: "small" }
      }
      height={{ min: "fit-content" }}
      onClick={() => {
        navigate("/" + slug);
        // setSelectedPostMeta({ slug: slug, source: toolboxTopics[obj], color: color });
        // setSelectedCat(null);
      }}
    >
      <Link style={{ textDecoration: "none" }} to={slug}>
        <Text size="small" color={"black"}>
          {title}
        </Text>
      </Link>
    </Box>
  );
};

export default ToolButton;
