import React, { useReducer, useEffect } from "react";
import AppContext from "./appContext";
import useWpApiData from "../hooks/useWpApiData";
import { useViewport } from "../shared/components/ReactContext_Viewport";


const initialState = {
    slug: null,
    postData: null,
    selectedCat: null,
    isInteractiveVisible: true,
    selectedPostMeta: null,
};


const AppProvider = ({ children, storybookState }) => {

    const [state, dispatch] = useReducer(
        appReducer,
        storybookState ? { ...initialState, ...storybookState } : initialState
    );
    const baseURL = ["127.0.0.1", "localhost"].indexOf(document.location.hostname) > -1 ? "https://hecpawstage.wpengine.com" : "";
    const postFetch = useWpApiData({postSlug:state.slug, baseURL});

    useEffect(()=>{
        if (postFetch.status === 'fetched'){
            setPostData(postFetch.data)
        }
    },[postFetch])


    const setPostData = (newVal) => {
        dispatch({ type: "SET_POST_DATA", to: newVal });
    }

    // if (!selectedPostMeta || !posts) return null;

    // const post = posts[0];
    // const source = selectedPostMeta.source;

    // const catID = post["toolbox_category"];

    const setSlug = (newVal) => {
        dispatch({ type: "SET_SLUG", to: newVal });
    };

    const setSelectedCat = (newVal) => {
        dispatch({ type: "SET_SELECTED_CAT", to: newVal });
    };
    const setIsInteractiveVisible = (newVal) => {
        dispatch({ type: "SET_IS_INTERACTIVE_VISIBLE", to: newVal });
    };
    const setSelectedPostMeta = (newVal) => {
        dispatch({ type: "SET_SELECTED_POST_META", to: newVal });
    };

    return (
        <AppContext.Provider
            value={{
                slug: state.slug,
                setSlug,
                postData: state.postData,
                selectedCat: state.selectedCat,
                setSelectedCat,
                isInteractiveVisible: state.isInteractiveVisible,
                setIsInteractiveVisible,
                selectedPostMeta: state.selectedPostMeta,
                setSelectedPostMeta,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const appReducer = (state, action) => {
    switch (action.type) {
        case "SET_POST_DATA":
            return {
                ...state,
                postData: action.to
            }
        case "SET_SLUG":
            return {
                ...state,
                slug: action.to,
            };
        case "SET_SELECTED_CAT":
            return {
                ...state,
                selectedCat: action.to,
            };
        case "SET_IS_INTERACTIVE_VISIBLE":
            return {
                ...state,
                isInteractiveVisible: action.to,
            };
        case "SET_SELECTED_POST_META":
            return {
                ...state,
                selectedPostMeta: action.to,
            };
        default:
            throw new Error();
    }
};

export default AppProvider;
