import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import AppProvider from "./context/AppProvider";
import {
  ViewportProvider,
  useViewport,
} from "./shared/components/ReactContext_Viewport";
import { Grommet, Box } from "grommet";
import cpawTheme from "./theme";
import Layout from "./Layout";
import ToolBoxSplash from "./components/ToolboxSplash";
import ToolBoxContent from "./components/ToolBoxContent";

import GoogleAnalytics from "./shared/components/GoogleAnalyticsWithRouter";

const App = () => {
  return (
    <Router>
      <AppProvider>
        <ViewportProvider>
          <GrommetContainer />
        </ViewportProvider>
      </AppProvider>
    </Router>
  );
};

const GrommetContainer = () => {
  const { appWidth } = useViewport();
  return (
    <Grommet
      className="app"
      full={appWidth < 1000 ? false : true}
      theme={cpawTheme}
    >
      <GoogleAnalytics
        siteID="UA-80888816-1"
        productionHostname="cpaw.headwaterseconomics.org"
      />
      <Box fill>
        <Routes>
          <Route
            exact
            path="/"
            // component={ToolBoxSplash}
            element={<Layout mainContent={<ToolBoxSplash />} />}
          />
          <Route
            path="/:postSlug"
            element={<Layout mainContent={<ToolBoxContent />} />}
          />
        </Routes>
      </Box>
    </Grommet>
  );
};

export default App;
