import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.initialize("UA-3386980-1");

const GoogleAnalytics = () => {
    const location = useLocation();

    // the following runs each time the URL changes
    useEffect(() => {
        if (window.location.hostname === 'headwaterseconomics.org') {
            ReactGA.pageview(window.location.pathname);
        }
    }, [location]);

    return null;
};
export default GoogleAnalytics;