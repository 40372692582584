import { useContext } from "react";
import appContext from "./context/appContext";
import { Box, Grid, Text } from "grommet";
import blurredBG from "./assets/blurredbg.jpg";
import { useViewport, screenSize } from "./shared/components/ReactContext_Viewport";
import ToolBoxNav from "./components/ToolBoxNav";

const Layout = ({ mainContent }) => {
    const { screenSize } = useViewport();
    const { selectedPostMeta } = useContext(appContext);
    return screenSize != "mobile" ? (
        <Box
            full
            height={{ min: "100vh" }}
            width={{ min: "100vw" }}
            style={{ zIndex: "2" }}
            background={{ image: "url(" + blurredBG + ")", size: "cover" }}
        >
            <Grid rows={["1fr", "auto"]} fill height={{ max: screenSize != "mobile" ? "100vh" : "fit-content", min: "100vh" }}>
                <Box gap="none" fill direction={screenSize !== "mobile" ? "row" : "column"}>
                    <ToolBoxNav />
                    <Box
                        fill
                        background={{ color: "light-1", opacity: "strong" }}
                        border={[
                            {
                                side: "left",
                                size: selectedPostMeta ? "small" : "0px",
                                color: selectedPostMeta ? selectedPostMeta.color : "white",
                            },
                        ]}
                        elevation="small"
                        style={{ zIndex: "3" }}
                    >
                        {mainContent}
                    </Box>
                </Box>
                <Box pad={"xsmall"} background={{ color: "light-1", opacity: "strong" }} justify={screenSize != "mobile" ? "end" : "center"} direction="row">
                    <Box>
                        <Text textAlign={"end"}>&copy;{new Date().getFullYear()} Headwaters Economics</Text>
                    </Box>
                </Box>
            </Grid>
        </Box>
    ) : (
        <>

        <ToolBoxNav />
        <Box
            fill
            background={{ color: "light-1", opacity: "strong" }}
            elevation="small"
        >
            {mainContent}
        </Box>
        <Box pad={"xsmall"} background={{ color: "light-1", opacity: "strong" }} justify={screenSize != "mobile" ? "end" : "center"} direction="row">
            <Box>
                <Text textAlign={"end"}>&copy;{new Date().getFullYear()} Headwaters Economics</Text>
            </Box>
        </Box>

        </>
    );
};
export default Layout;
