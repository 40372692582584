const cpawTheme = {
    "global": {
        "colors": {
            "brand": "#C36535",
            "accent-1": "#D8852A",
            "accent-2": "#718C49",
            "accent-3": "#61C0CD",
            "accent-4": "#763614",
            "neutral-1": "#726659",
            "neutral-2": "#2C3B0A",
            "neutral-3": "#306066",
            "neutral-4": "#6F6555",
            "cpaw-teal": "#33788F",
            "cpaw-sage": "#829254",
            "cpaw-orange": "#BBA036",
            "cpaw-burntorange": "#C2561A",
            "cpaw-red": "#9B3329",
            focus: "none"
        },
        "opacity": {
            "strong": ".9",
        },
        "font": {
          "family": "Museo Sans, Helvetica, sans-serif",
        },
        "link": {
            "color": {
                "light": "neutral-2",
                "dark": "accent-2"
            }
        },
        "heading": {
            "level": {
                "1": {
                  "small": {
                    "size": "28px",
                    "height": "33px",
                    "maxWidth": "567px"
                  },
                  "medium": {
                    "size": "42px",
                    "height": "47px",
                    "maxWidth": "833px"
                  },
                  "large": {
                    "size": "68px",
                    "height": "73px",
                    "maxWidth": "1367px"
                  },
                  "xlarge": {
                    "size": "95px",
                    "height": "100px",
                    "maxWidth": "1900px"
                  }
                },
                "2": {
                  "small": {
                    "size": "25px",
                    "height": "30px",
                    "maxWidth": "500px"
                  },
                  "medium": {
                    "size": "35px",
                    "height": "40px",
                    "maxWidth": "700px"
                  },
                  "large": {
                    "size": "45px",
                    "height": "50px",
                    "maxWidth": "900px"
                  },
                  "xlarge": {
                    "size": "55px",
                    "height": "60px",
                    "maxWidth": "1100px"
                  }
                },
                "3": {
                  "small": {
                    "size": "22px",
                    "height": "27px",
                    "maxWidth": "433px"
                  },
                  "medium": {
                    "size": "28px",
                    "height": "33px",
                    "maxWidth": "567px"
                  },
                  "large": {
                    "size": "35px",
                    "height": "40px",
                    "maxWidth": "700px"
                  },
                  "xlarge": {
                    "size": "42px",
                    "height": "47px",
                    "maxWidth": "833px"
                  }
                },
                "4": {
                  "small": {
                    "size": "18px",
                    "height": "23px",
                    "maxWidth": "367px"
                  },
                  "medium": {
                    "size": "22px",
                    "height": "27px",
                    "maxWidth": "433px"
                  },
                  "large": {
                    "size": "25px",
                    "height": "30px",
                    "maxWidth": "500px"
                  },
                  "xlarge": {
                    "size": "28px",
                    "height": "33px",
                    "maxWidth": "567px"
                  }
                },
                "5": {
                  "small": {
                    "size": "13px",
                    "height": "18px",
                    "maxWidth": "267px"
                  },
                  "medium": {
                    "size": "13px",
                    "height": "18px",
                    "maxWidth": "267px"
                  },
                  "large": {
                    "size": "13px",
                    "height": "18px",
                    "maxWidth": "267px"
                  },
                  "xlarge": {
                    "size": "13px",
                    "height": "18px",
                    "maxWidth": "267px"
                  }
                },
                "6": {
                  "small": {
                    "size": "12px",
                    "height": "17px",
                    "maxWidth": "233px"
                  },
                  "medium": {
                    "size": "12px",
                    "height": "17px",
                    "maxWidth": "233px"
                  },
                  "large": {
                    "size": "12px",
                    "height": "17px",
                    "maxWidth": "233px"
                  },
                  "xlarge": {
                    "size": "12px",
                    "height": "17px",
                    "maxWidth": "233px"
                  }
                }
              },
              "font": {
                "family": "Museo Sans Condensed"
              },
        },

    },
    tip: {
        content: {
            background: {color: "white", opacity: "strong"}
        },
    }
}

export default cpawTheme;