
import ComPlanThumb from "./CommunityPlans.jpg";
import LandDevThumb from "./LandDev.jpg";
import BuildCodeThumb from "./BuildCodes.jpg";
import FundEngageThumb from "./FundingEngage.jpg";
import FuelsReductionThumb from "./FuelsReduction.jpg";

const toolboxCategoriesByName = {
    "Community Plans":{
        image: ComPlanThumb,
        color:"cpaw-teal"
    },
    "Land Development":{
        image: LandDevThumb,
        color:"cpaw-orange"
    },
    "Building and Fire Codes":{
        image: BuildCodeThumb,
        color:"cpaw-burntorange"
    },
    "Fuels Treatment":{
        image: FuelsReductionThumb,
        color:"cpaw-sage"
    },
    "Funding and Engagement":{
        image: FundEngageThumb,
        color:"cpaw-red"
    },

}

const toolboxTopics = [
    {
        title: "Comprehensive Plan",
        category: "Community Plans",
        slug: "comprehensive-plan"
    },
    {
        title: "Community Wildfire Protection Plan",
        category: "Community Plans",
        slug: "community-wildfire-protection-plan"
    },
    {
        title: "Hazard Mitigation Plan",
        category: "Community Plans",
        slug: "hazard-mitigation-plan"
    },
    {
        title: "Subdivision Regulations",
        category: "Land Development",
        slug: "subdivision-regulations"
    },
    {
        title: "Zoning Regulations",
        category: "Land Development",
        slug: "zoning-regulations"
    },
    {
        title: "Open Space Policies",
        category: "Land Development",
        slug: "open-space-policies"
    },
    {
        title: "Landscaping Regulations",
        category: "Land Development",
        slug: "landscape-ordinances"
    },
    {
        title: "Fire Code",
        category: "Building and Fire Codes",
        slug: "fire-code"
    },
    {
        title: "Building Code",
        category: "Building and Fire Codes",
        slug: "building-code"
    },
    {
        title: "Wildland-Urban Interface (WUI) Code",
        category: "Building and Fire Codes",
        slug: "wildland-urban-interface-codes"
    },
    {
        title: "Hazardous Fuels Reduction",
        category: "Fuels Treatment",
        slug: "hazardous-fuels-reduction"
    },
    {
        title: "Assess Risk",
        category: "Funding and Engagement",
        slug: "assess-risk"
    },
    {
        title: "Engage Your Community",
        category: "Funding and Engagement",
        slug: "engage-your-community"
    },
    {
        title: "Fund the Work",
        category: "Funding and Engagement",
        slug: "fund-the-work"
    },
]

export { toolboxCategoriesByName, toolboxTopics};