
import { Box, Text, Tip } from "grommet";
import { useContext } from "react";
import { useViewport } from "../shared/components/ReactContext_Viewport";
import appContext from "../context/appContext";
import { toolboxCategoriesByName } from "../assets/toolboxInfo";

const CatDescriptionButton = (props) => {
    const { ratio  } = useViewport();
    const { setSelectedCat } = useContext(appContext);
    const {
        color = "brand",
        cat = "",
        icon = "",
        top = "unset",
        right = "unset",
        bottom = "unset",
        left = "unset",
        description = "",
    } = props;
    return (
        <Tip
            content={
                
                <Box direction="column">
                    <Text textAlign="center">Discover more about</Text>
                    <Text color={color} weight={"bold"} textAlign="center">
                        {cat}
                    </Text>
                </Box>
            }
            dropProps={{ align: { bottom: "top" } }}
        >
            <Box
                round="100%"
                height={"auto"}
                width={"12%"}
                overflow="hidden"
                border={{ color: color, size: ratio * 8 + "px" }}
                className={"shadow"}
                style={{
                    aspectRatio: "1 / 1",
                    zIndex: "1",
                    position: "absolute",
                    top: top + "%",
                    right: right + "%",
                    bottom: bottom + "%",
                    left: left + "%",
                }}
                onClick={() => {
                    setSelectedCat({name:cat,color:toolboxCategoriesByName[cat].color,icon:icon,description:description});
                }}
            ></Box>
        </Tip>
    );
};
export default CatDescriptionButton