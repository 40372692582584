import { Box, Heading } from "grommet";
import { useViewport } from "../shared/components/ReactContext_Viewport";


const ToolListIcon = ({ input, cat, color, icon }) => {
    const catClass = cat.replace(/\s+/g, "-").toLowerCase();
    const { screenSize } = useViewport();

    return (
        <Box
            height={{ min: "xsmall", size: "small" }}
            className={"shadow " + catClass + "-icon"}
            style={{ zIndex: screenSize != "mobile" ? "3" : "inherit" }}
            direction="row"
            align="center"
            fill={"horizontal"}
        >
            <Box
                round="100%"
                height={"auto"}
                width={"xsmall"}
                overflow="hidden"
                border={{ color: color, size: "medium" }}
                className={"shadow-inset"}
                background={{ image: "url(" + icon + ")", size: "cover" }}
                style={{ aspectRatio: "1 / 1", zIndex: "3" }}
            ></Box>
            <Box
                round={"xxsmall"}
                background={color}
                pad={{ left: "1.25em", right: "xsmall", vertical: "xxsmall" }}
                style={{ zIndex: "0", marginLeft: "-1em" }}
                flex="grow"
            >
                <Heading level="3" size="large" color="white" margin={"none"}>
                    {cat.replace(" and ", " & ")}
                </Heading>
            </Box>
        </Box>
    );
};
export default ToolListIcon;
