import { Box, Text } from "grommet";
import { useContext } from "react";
import appContext from "../context/appContext";
import ToolButton from "./ToolButton";
import { useViewport } from "../shared/components/ReactContext_Viewport";

const ToolList = ({ input, cat, color }) => {
    const { screenSize } = useViewport();
    const { selectedCat } = useContext(appContext);
    const catClass = cat.replace(/\s+/g, "-").toLowerCase();
    const ToolCats = input
        .filter((item) => item.category === cat)
        .map((item, i) => (
            <ToolButton key={"ToolCat-" + i} title={item.title} content={input} color={color} slug={item.slug} />
        ));

    return (
        <Box
            direction="column"
            gap="xxsmall"
            width={{ min: "fit-content" }}
            margin={{ bottom: "small" }}
            className={catClass + " tool-cat"}
            background={(selectedCat && selectedCat.name) === cat ? { color: color, opacity: "weak" } : { color: color, opacity: "0" }}
            style={{ transition: "all ease-in-out .5s" }}
            height={{ min: "fit-content" }}
        >
            <Box
                border={{ side: "left", size: "medium", color: color }}
                pad={{ horizontal: screenSize !== "mobile" ? "xsmall" : "small" }}
                background={{ color: color, opacity: "weak" }}
            >
                <Text size="small" weight={"bolder"} color={color}>
                    {cat.replace(" and ", " & ")}
                </Text>
            </Box>
            {ToolCats}
        </Box>
    );
};
export default ToolList;