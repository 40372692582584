import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import appContext from "../context/appContext";
import { useViewport } from "../shared/components/ReactContext_Viewport";
import { toolboxTopics, toolboxCategoriesByName } from "../assets/toolboxInfo";
import { Anchor, Box, Grid, Heading, Image, Spinner, Text } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";
import Sidebar from "./Sidebar";

const ToolBoxContent = () => {
    const { setSlug, postData } = useContext(appContext);
    const { appWidth, screenSize } = useViewport();
    let { postSlug } = useParams();

    useEffect(() => {
        setSlug(postSlug);
    }, [postSlug]);

    if (!postData) {
        return (
        <Box fill justify="center">
            <Box direction="row" justify="center" alignSelf="center">
                <Text size="xlarge">
                    Loading...
                </Text>
                <Spinner />
            </Box>
            
        </Box>
        );
    }
    
   
    const selectedTopic = toolboxTopics.find((item) => item.slug === postData.slug);
    const selectedPostMeta = { slug: postData.slug, source: selectedTopic, color: toolboxCategoriesByName[selectedTopic.category].color };
    const source = selectedPostMeta.source;
    const categoryInfo = toolboxCategoriesByName[selectedTopic.category];

    return appWidth > "1200" ? (
        <Box
            fill
            flex
            justify="between"
            pad={{
                top: screenSize === "tablet" ? "medium" : "large",
                left: screenSize === "tablet" ? "2em" : "3.5em",
            }}
            background={{ color: "light-1", opacity: ".8" }}
            animation={"fadeIn"}
        >
            <Grid
                areas={[
                    { name: "header", start: [0, 0], end: [2, 0] },
                    { name: "main", start: [0, 1], end: [1, 1] },
                    { name: "sidebar", start: [2, 1], end: [2, 1] },
                ]}
                columns={["1fr", "1fr", "1fr"]}
                rows={["auto", "1fr", "auto"]}
                fill
            >
                <Box
                    gridArea="header"
                    pad={{ top: "small" }}
                    border={{ side: "bottom", color: selectedPostMeta.color, size: "xsmall" }}
                >
                    <Box direction="row" justify="start" pad={{ right: "medium" }} gap="small">
                        <Box
                            alignSelf="center"
                            flex={"shrink"}
                            height={{ max: "xsmall" }}
                            width={{ max: "xsmall" }}
                            round={"100%"}
                            overflow={"hidden"}
                            border={{ color: selectedPostMeta.color, size: "medium" }}
                            margin={{ bottom: "xsmall" }}
                        >
                            <Image height={"100%"} src={categoryInfo.image} />
                        </Box>
                        <Box>
                            <Text color={selectedPostMeta.color} size="small">
                                {selectedTopic.category}
                            </Text>
                            <Heading level="2" margin={"none"} size="xlarge">
                                {postData.title.rendered}
                            </Heading>
                        </Box>
                    </Box>
                </Box>
                <Box
                    gridArea="main"
                    pad={{ vertical: "medium", right: "medium" }}
                    overflow={{ vertical: "scroll" }}
                    height={{ min: "fit-content" }}
                >
                    <div dangerouslySetInnerHTML={{ __html: postData.content.rendered }} />
                </Box>
                <Box
                    gridArea="sidebar"
                    background={{ color: selectedPostMeta.color, opacity: "weak" }}
                    pad={{
                        vertical: "medium",
                        left: "medium",
                        right: screenSize === "desktop" ? "xlarge" : "medium",
                    }}
                >
                    <Sidebar post={postData} selectedPostMeta={selectedPostMeta} />
                </Box>
            </Grid>
        </Box>
    ) : (
        // post && (
        <Box height={{ min: "fit-content" }} overflow={{ vertical: appWidth < "1200" ? "scroll" : "auto" }}>
            <Box
                pad={{ top: "xsmall", horizontal: "large" }}
                border={{ side: "bottom", color: selectedPostMeta.color, size: "xsmall" }}
                background={{ color: selectedPostMeta.color, opacity: "weak" }}
                height={{ min: "fit-content" }}
            >
                <Text color={selectedPostMeta.color} size="small">
                    {source.category}
                </Text>
                <Heading level="2" margin={"none"} size="xlarge">
                    {postData.title.rendered}
                </Heading>
            </Box>

            <Box pad={{ vertical: "small", horizontal: "large" }} height={{ min: "fit-content" }}>
                <div dangerouslySetInnerHTML={{ __html: postData.content.rendered }} />
            </Box>

            <Box
                background={{ color: selectedPostMeta.color, opacity: "weak" }}
                pad={{ vertical: "medium", horizontal: "large" }}
                height={{ min: "fit-content" }}
            >
                <Heading level="3" margin={{ bottom: "xsmall" }}>
                    Resources
                </Heading>
                <Box gap="xsmall" height={{ min: "fit-content" }}>
                    {postData.acf.resources.map((item, i) => (
                        <Box key={"resourceListItem-" + i} height={{ min: "fit-content" }}>
                            <Anchor
                                target="_blank"
                                icon={<FontAwesomeIcon icon={faExternalLink} />}
                                label={
                                    <Box>
                                        <Box>
                                            <Text weight={"bold"}>{item.resource_label}</Text>
                                        </Box>
                                        <Box>
                                            <Text size="xsmall" weight={"normal"}>
                                                {item.resource_provider}
                                            </Text>
                                        </Box>
                                    </Box>
                                }
                                href={item.resource_url}
                                title={item.resource_label}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
        // )
    );
};
export default ToolBoxContent;
