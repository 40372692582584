import React, { useContext } from "react";
import appContext from "../context/appContext";
import { Box, Button, Paragraph, Text, Heading, Layer } from "grommet";
import SplashHome from "../assets/SplashHome.jpg";
import InteractiveCanvas from "../assets/InteractiveCanvas.jpg";
import ComPlanThumb from "../assets/CommunityPlans.jpg";
import LandDevThumb from "../assets/LandDev.jpg";
import BuildCodeThumb from "../assets/BuildCodes.jpg";
import FuelsReductionThumb from "../assets/FuelsReduction.jpg";
import FundEngageThumb from "../assets/FundingEngage.jpg";
import CatOverview from "./CatOverview";
import CatDescription from "./CatDescription";
import CatDescriptionButton from "./CatDescriptionButton";
import ToolListIcon from "./ToolListIcon";
import ToolList from "./ToolList";
import { toolboxTopics } from "../assets/toolboxInfo";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/pro-light-svg-icons";
import { useViewport } from "../shared/components/ReactContext_Viewport";
import ModalContent from "./ModalContent";

const ToolBoxSplash = () => {
    const { screenSize, appWidth, ratio } = useViewport();
    const { setIsInteractiveVisible, isInteractiveVisible, setSelectedCat } = useContext(appContext);

    // screenSize === "mobile" && setIsInteractiveVisible(false);

    return (
        <>  {screenSize != "mobile" ? (
            isInteractiveVisible && (
                <Layer
                    id="scrollmodal"
                    onEsc={() => setIsInteractiveVisible(false)}
                    onClickOutside={() => setIsInteractiveVisible(false)}
                    modal
                >
                    <ModalContent />
                </Layer>
            )) : "" }

            <Box
                fill
                // background={{image: "url("+SplashHome+")",opacity: "weak"}}
                background={{
                    image: "url(" + InteractiveCanvas + ")",
                    position: "bottom right",
                    size: ratio > "1.3" ? "cover" : "contain",
                    color: "#BCE2F8",
                }}
                overflow={{ vertical: "auto" }}
                height={{ min: "fit-content", max: "100%", height: "100%" }}
                style={{ position: "relative" }}
                flex="grow"
            >
                <Box
                    direction={appWidth < "1024" ? "column" : "row"}
                    justify="between"
                    gap="large"
                    height={{ min: "fit-content" }}
                    pad={{ top: "medium", bottom: "large", horizontal: "large" }}
                    background={"linear-gradient(0deg, rgba(255,255,255,.3), rgba(255,255,255,1))"}
                >
                    <Heading level={"2"} size={appWidth > "1300" ? "xlarge" : "large"} margin={{ bottom: "none" }}>
                        Land use planning can help your community survive a wildfire
                    </Heading>
                    {appWidth < "1024" ? ( ""
                        // <Button
                        //     primary
                        //     color={"brand"}
                        //     icon={<FontAwesomeIcon icon={faPlayCircle} />}
                        //     label={"Show Interactive"}
                        //     size={"medium"}
                        //     fill={false}
                        //     onClick={() => {
                        //         setIsInteractiveVisible(true);
                        //     }}
                        // />
                    ) : (
                        <Box
                            width={{ min: "fit-content", max: "300px" }}
                            height={{ min: "fit-content" }}
                            onClick={() => {
                                setIsInteractiveVisible(true);
                            }}
                            style={{ zIndex: "2" }}
                        >
                            <Box
                                pad="small"
                                background={{ color: "dark-2", image: "url(" + SplashHome + ")" }}
                                width={"100%"}
                                height={"auto"}
                                round="xxsmall"
                                justify="around"
                                elevation="medium"
                                border={{ size: "medium", color: "brand" }}
                                className="shadow-inset"
                                style={{ aspectRatio: "16 / 9", zIndex: "1" }}
                            >
                                <Box
                                    round="xxsmall"
                                    fill={false}
                                    width={{ min: "fit-content" }}
                                    background={"brand"}
                                    direction="row"
                                    justify="center"
                                    align="center"
                                    gap="small"
                                    pad={{ vertical: "xsmall", horizontal: "small" }}
                                >
                                    <FontAwesomeIcon icon={faPlayCircle} size={"lg"} />
                                    <Text size="medium">Show Interactive</Text>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box fill flex={screenSize !== "mobile" ? "shrink" : "grow"} style={{ position: "relative" }}>
                    {screenSize !== "mobile" ? (
                        <>
                            <Box
                                fill
                                onClick={() => {
                                    // setCatOverview(<SplashCat />);
                                    setSelectedCat(null);
                                }}
                                style={{ position: "absolute", bottom: "0", left: "0", zIndex: "1" }}
                            />
                            <Box
                                style={{ position: "relative" }}
                                background={"linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,.3))"}
                            >
                                <CatOverview />
                            </Box>
                            <Box
                                // background={{image: "url("+InteractiveCanvas+")", position: "bottom", size: "contain", color: "#BCE2F8"}}
                                fill="horizontal"
                                height={"auto"}
                                style={{ position: "absolute", bottom: "0", right: "0", aspectRatio: "8 / 5" }}
                            >
                                <CatDescriptionButton
                                    color="cpaw-teal"
                                    cat="Community Plans"
                                    icon={ComPlanThumb}
                                    bottom="15"
                                    right="30"
                                    description={
                                        <Paragraph>
                                            Community plans express long-term vision, policy direction, and strategies
                                            for growth and development.
                                        </Paragraph>
                                    }
                                />
                                <CatDescriptionButton
                                    color="cpaw-orange"
                                    cat="Land Development"
                                    icon={LandDevThumb}
                                    bottom="30"
                                    right="12"
                                    description={
                                        <Paragraph>
                                            Landscaping, housing density, and open space can be planned and regulated to
                                            reduce wildfire risk to homes and properties.
                                        </Paragraph>
                                    }
                                />
                                <CatDescriptionButton
                                    color="cpaw-burntorange"
                                    cat="Building and Fire Codes"
                                    icon={BuildCodeThumb}
                                    bottom="20"
                                    right="43"
                                    description={
                                        <Paragraph>
                                            Regulations promote fire prevention by codifying safety standards for
                                            building construction, design, and function.
                                        </Paragraph>
                                    }
                                />
                                <CatDescriptionButton
                                    color="cpaw-sage"
                                    cat="Fuels Treatment"
                                    icon={FuelsReductionThumb}
                                    bottom="38"
                                    right="34"
                                    description={
                                        <Paragraph>
                                            Removing or thinning vegetation can mitigate wildfire hazards by reducing
                                            the continuity and availability of fuels.
                                        </Paragraph>
                                    }
                                />
                                <CatDescriptionButton
                                    color="cpaw-red"
                                    cat="Funding and Engagement"
                                    icon={FundEngageThumb}
                                    bottom="1"
                                    right="12"
                                    description={
                                        <Paragraph>
                                            Assessing and mapping wildfire risk helps engage the community and secure
                                            funding for wildfire mitigation projects.
                                        </Paragraph>
                                    }
                                />
                            </Box>
                        </>
                    ) : (
                        <Box
                            // overflow={"scroll"}
                            // fill
                            flex="shrink"
                            background={"linear-gradient(180deg, rgba(255,255,255,.3) 40%, rgba(255,255,255,0) 60%)"}
                            pad={{ horizontal: "large" }}
                        >
                            <CatDescription
                                header={
                                    <ToolListIcon
                                        input={toolboxTopics}
                                        color={"cpaw-teal"}
                                        icon={ComPlanThumb}
                                        cat={"Community Plans"}
                                    />
                                }
                            >
                                <Paragraph>
                                    Community plans express long-term vision, policy direction, and strategies for
                                    growth and development.
                                </Paragraph>
                                <ToolList input={toolboxTopics} cat={"Community Plans"} color={"cpaw-teal"} />
                            </CatDescription>

                            <CatDescription
                                header={
                                    <ToolListIcon
                                        input={toolboxTopics}
                                        color={"cpaw-orange"}
                                        icon={LandDevThumb}
                                        cat={"Land Development"}
                                    />
                                }
                            >
                                <Paragraph>
                                    Landscaping, housing density, and open space can be planned and regulated to reduce
                                    wildfire risk to homes and properties.
                                </Paragraph>
                                <ToolList input={toolboxTopics} cat={"Land Development"} color={"cpaw-orange"} />
                            </CatDescription>

                            <CatDescription
                                header={
                                    <ToolListIcon
                                        input={toolboxTopics}
                                        color={"cpaw-burntorange"}
                                        icon={BuildCodeThumb}
                                        cat={"Building and Fire Codes"}
                                    />
                                }
                            >
                                <Paragraph>
                                    Regulations promote fire prevention by codifying safety standards for building
                                    construction, design, and function.
                                </Paragraph>
                                <ToolList
                                    input={toolboxTopics}
                                    cat={"Building and Fire Codes"}
                                    color={"cpaw-burntorange"}
                                />
                            </CatDescription>

                            <CatDescription
                                header={
                                    <ToolListIcon
                                        input={toolboxTopics}
                                        color={"cpaw-sage"}
                                        icon={FuelsReductionThumb}
                                        cat={"Fuels Treatment"}
                                    />
                                }
                            >
                                <Paragraph>
                                    Removing or thinning vegetation can mitigate wildfire hazards by reducing the
                                    continuity and availability of fuels.
                                </Paragraph>
                                <ToolList input={toolboxTopics} cat={"Fuels Treatment"} color={"cpaw-sage"} />
                            </CatDescription>

                            <CatDescription
                                header={
                                    <ToolListIcon
                                        input={toolboxTopics}
                                        color={"cpaw-red"}
                                        icon={FundEngageThumb}
                                        cat={"Funding and Engagement"}
                                    />
                                }
                            >
                                <Paragraph>
                                    Assessing and mapping wildfire risk helps engage the community and secure funding
                                    for wildfire mitigation projects.
                                </Paragraph>
                                <ToolList input={toolboxTopics} cat={"Funding and Engagement"} color={"cpaw-red"} />
                            </CatDescription>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ToolBoxSplash;
