import { Box, Paragraph } from "grommet";
import { useViewport } from "../shared/components/ReactContext_Viewport";

const SplashCat = () => {
    const { screenSize } = useViewport();
    return (
        <Box
            margin={{ horizontal: "large" }}
            animation={{ type: screenSize !== "mobile" ? "fadeIn" : null }}
            height={{ min: "fit-content" }}
            gap="large"
            style={{ zIndex: "2" }}
        >
            <Box gap="large" justify="between">
                <Box
                    background={{ color: "white", opacity: ".9" }}
                    pad="medium"
                    round="xxsmall"
                    elevation="small"
                    width={"medium"}
                >
                    <Paragraph size={screenSize === "desktop" ? "large" : "medium"} fill margin={"none"}>
                        Click on the circles or use the navigation to learn more about land use planning.
                    </Paragraph>
                </Box>
            </Box>
        </Box>
    );
};
export default SplashCat;