import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, DropButton, Header, Heading, Text } from "grommet";
import { Link } from "react-router-dom";
import { useViewport} from "../shared/components/ReactContext_Viewport";
import { useMatch } from "react-router-dom";
import { toolboxTopics } from "../assets/toolboxInfo";
import {
    faArrowAltToLeft,
    faBars,
    faHome
} from "@fortawesome/pro-light-svg-icons";

import appContext from "../context/appContext";
import ToolList from "./ToolList";
import { useState, useContext } from "react";

const ToolBoxNav = () => {
    let match = useMatch({
        path: "/",
        exact: true,
    });
    const { screenSize } = useViewport();

    const { setIsInteractiveVisible } = useContext(appContext);

    const [open, setOpen] = useState();
    const [active, setActive] = useState(null);
  
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return screenSize !== "mobile" ? (
        <Box
            fill="vertical"
            pad={{ top: "medium", left: "medium" }}
            direction="column"
            gap="xxsmall"
            width={{ min: "fit-content", max: "medium", width: "large" }}
            background={{ color: "light-1", opacity: "strong" }}
            height={{ min: "fit-content" }}
        >
            <Link to="/" style={{ textDecoration: "none", color: "#333" }}>
            <Box direction="row" height={{ min: "fit-content" }}>
                <Box width={"2.25rem"} height={"4rem"} alignSelf={"end"}>
                    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.33 720">
                        <path
                            fill="#C36535"
                            d="M465.92,353.61c-34.32-65.79-36.59-81.92-42.34-92.97-3.45-6.62-6.37-6.97-9.55-5.75-4.49,1.73-3.45,13.46-6.21,20.71,0,0-13.12,41.42-46.94,49.36,22.44-35.21,25.17-69.31,15.3-107.46-20.01-77.28-112.29-156.14-133-170.41-19.71-13.58-53.39-32.68-74.56-40.04-8.66-3.01-32.72-8.97-35.44-6.44-2.71,2.52,.87,7.57,4.43,14.77,3.57,7.2,25.03,45.16,36.53,95.51,11.5,50.34-19.45,85.53-72.37,139.86-12.06,12.38-23.46,24.08-33.79,36.05C-2.71,360.06-19.92,472.5,24.14,573.32c39.55,90.48,119.43,146.69,208.49,146.68h.02c2,0,4.03-.03,5.65-.08,78.58,1.23,154.35-41.25,202.18-113.81,50.47-76.57,59.76-186.72,25.43-252.51Z"
                        />
                    </svg>
                </Box>
                <Heading level={"1"} margin="none">
                    <Text size={"4rem"} weight={"bolder"}>
                        Fire
                    </Text>
                    <Text size="4rem" weight={"lighter"}>
                        topia
                    </Text>
                </Heading>
            </Box>
            </Link>
            <Box
                fill="horizontal"
                margin={{ bottom: "xsmall" }}
                height={{ min: "fit-content" }}
                direction="row"
                justify="start"
                round={{ corner: "left", size: "large" }}
                background={!match ? { color: "light-4", opacity: "1" } : { color: "none" }}
                overflow="hidden"
            >
                <Box
                    flex="grow"
                    // onClick={() => {
                    //     setSelectedPostMeta(null);
                    // }}
                    className={"toolbox-nav" + (match === null ? " active" : "")}
                    focusIndicator={false}
                >
                    <Link style={{ textDecoration: "none", color: "#222" }} to="/" onClick={() => setIsInteractiveVisible(false)}>
                        <Box
                            direction="row"
                            gap="xsmall"
                            pad={{ horizontal: "small", vertical: "xxsmall" }}
                            hoverIndicator={{ background: { color: "accent-2", border: "none" } }}
                            focusIndicator={false}
                        >
                            <FontAwesomeIcon color="dark-1" icon={faArrowAltToLeft} size={"1x"} />
                            <Text size="small">Overview</Text>
                        </Box>
                    </Link>
                </Box>
                <Box
                    fill="horizontal"
                    direction="row"
                    gap="xsmall"
                    pad={{ horizontal: "small", vertical: "xxsmall" }}
                    border={{ side: "left", size: "xsmall", color: "#bababa" }}
                    hoverIndicator={{ background: { color: "brand" } }}
                    onClick={() => {
                        window.location = document.location.hostname === 'localhost' ? "https://hecpawdev.wpengine.com" : document.location.origin;
                    }}
                >
                    <FontAwesomeIcon icon={faHome} size={"1x"} />
                    <Text size="small">Back to CPAW</Text>
                </Box>
            </Box>

            <Box overflow={{ vertical: "auto" }}>
                <ToolList input={toolboxTopics} cat="Community Plans" color="cpaw-teal" />

                <ToolList input={toolboxTopics} cat="Land Development" color="cpaw-orange" />

                <ToolList input={toolboxTopics} cat="Building and Fire Codes" color="cpaw-burntorange" />

                <ToolList input={toolboxTopics} cat="Fuels Treatment" color="cpaw-sage" />

                <ToolList input={toolboxTopics} cat="Funding and Engagement" color="cpaw-red" />
            </Box>
        </Box>
    ) : (
        <Header className="mobile-header" elevation="small" background={"light-1"} style={{ zIndex: "2" }}>
            <Link to="/" style={{ textDecoration: "none", color: "#333" }}>
                <Box pad={{ horizontal: "small", vertical: "xsmall" }} direction="row">
                    <Box width={"2rem"} height={"2.5rem"} alignSelf={"end"} pad={{ bottom: "xsmall" }}>
                        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.33 720">
                            <path
                                fill="#C36535"
                                d="M465.92,353.61c-34.32-65.79-36.59-81.92-42.34-92.97-3.45-6.62-6.37-6.97-9.55-5.75-4.49,1.73-3.45,13.46-6.21,20.71,0,0-13.12,41.42-46.94,49.36,22.44-35.21,25.17-69.31,15.3-107.46-20.01-77.28-112.29-156.14-133-170.41-19.71-13.58-53.39-32.68-74.56-40.04-8.66-3.01-32.72-8.97-35.44-6.44-2.71,2.52,.87,7.57,4.43,14.77,3.57,7.2,25.03,45.16,36.53,95.51,11.5,50.34-19.45,85.53-72.37,139.86-12.06,12.38-23.46,24.08-33.79,36.05C-2.71,360.06-19.92,472.5,24.14,573.32c39.55,90.48,119.43,146.69,208.49,146.68h.02c2,0,4.03-.03,5.65-.08,78.58,1.23,154.35-41.25,202.18-113.81,50.47-76.57,59.76-186.72,25.43-252.51Z"
                            />
                        </svg>
                    </Box>
                    <Heading level={"1"} margin="none">
                        <Text size={"2.5rem"} weight={"bolder"}>
                            Fire
                        </Text>
                        <Text size="2.5rem" weight={"lighter"}>
                            topia
                        </Text>
                    </Heading>
                </Box>
            </Link>
            <DropButton
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                dropContent={
                    <Box onClick={onClose} overflow={{ vertical: "scroll" }}>
                        <Box
                            fill="horizontal"
                            height={{ min: "fit-content" }}
                            direction="row"
                            justify="between"
                            background={!active ? { color: "light-4", opacity: "1" } : { color: "none" }}
                        >
                            <Box
                                flex="grow"
                                // onClick={() => {
                                //     setSelectedPostMeta(null);
                                // }}
                                className={"toolbox-nav" + (match === null ? " active" : "")}
                                focusIndicator={false}
                                justify="center"
                            >
                                <Link style={{ textDecoration: "none", color: "#222" }} to="/">
                                    <Box
                                        direction="row"
                                        gap="xsmall"
                                        pad={{ horizontal: "small", vertical: "xxsmall" }}
                                        hoverIndicator={{ background: { color: "accent-2" } }}
                                        focusIndicator={false}
                                    >
                                        <FontAwesomeIcon color="dark-1" icon={faArrowAltToLeft} size={"1x"} />
                                        <Text size="small">Overview</Text>
                                    </Box>
                                </Link>
                            </Box>
                            <Box
                                flex="grow"
                                direction="row"
                                gap="xsmall"
                                pad={{ horizontal: "medium", vertical: "small" }}
                                border={{ side: "left", size: "xsmall", color: "#bababa" }}
                                hoverIndicator={{ background: { color: "brand" } }}
                                onClick={() => {
                                    window.location = document.location.hostname === 'localhost' ? "https://hecpawdev.wpengine.com" : document.location.origin;
                                }}
                            >
                                <FontAwesomeIcon icon={faHome} size={"1x"} />
                                <Text size="small">CPAW</Text>
                            </Box>
                        </Box>

                        <ToolList input={toolboxTopics} cat="Community Plans" color="cpaw-teal" />

                        <ToolList input={toolboxTopics} cat="Land Development" color="cpaw-orange" />

                        <ToolList input={toolboxTopics} cat="Building and Fire Codes" color="cpaw-burntorange" />

                        <ToolList input={toolboxTopics} cat="Fuels Treatment" color="cpaw-sage" />

                        <ToolList input={toolboxTopics} cat="Funding and Engagement" color="cpaw-red" />
                    </Box>
                }
            >
                <Box pad={{ vertical: "xsmall", horizontal: "medium" }}>
                    <FontAwesomeIcon icon={faBars} size="2x" />
                </Box>
            </DropButton>
        </Header>
    );
};

export default ToolBoxNav;
