import React, { useRef, useEffect, useState, useContext } from "react";
import appContext from "../context/appContext";
import "../App.scss";
import {  Box, Button, Paragraph, Text, Image } from "grommet";
import { useViewport } from "../shared/components/ReactContext_Viewport";
import { VideoScroll } from "react-video-scroll";

import bgVideo from "../assets/Firetopia2_ff.mp4";
import bgVideoWebm from "../assets/Firetopia2_ff.webm";
import cpawLogo from "../assets/logo.png";
import blurredBG from "../assets/blurredbg.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";

const ModalContent = () => {

    const { screenSize } = useViewport();
    const [modalHeight, setModalHeight] = useState();
    const { setIsInteractiveVisible } = useContext(appContext);

    useEffect(() => {
        let scrollContainer = document.querySelector("#scrollcontainer");
            if (scrollContainer) {
                setModalHeight(scrollContainer.offsetHeight);
            }
    },[]);

    const ScrollSlide = (props) => {
        const {
            align = "center",
            height = "auto",
            width = "medium",
            color = "dark-1",
            topSticky = "1em",
            elevation = "small",
        } = props;
        const mobileOffset = "calc(" + height + " - .27 * 100vw)";
        return (
            <Box
                className="slide"
                justify={screenSize !== "mobile" ? align : "center"}
                direction="row"
                height={screenSize !== "mobile" ? height : mobileOffset}
            >
                <Box
                    background={{ color: color, opacity: "0.9" }}
                    pad={{ horizontal: "medium", vertical: "medium" }}
                    margin={{ bottom: "4em" }}
                    width={{ width: width, max: "100%" }}
                    height="fit-content"
                    elevation={elevation}
                    round={"xsmall"}
                    className="content"
                    style={{ top: screenSize === "mobile" ? "calc( .58 * 100vw)" : topSticky }}
                >
                    {props.children}
                </Box>
            </Box>
        );
    };

    return (
        <Box
            width={{ max: "100%", width: "xlarge" }}
            height={{ max: "100vh", height: "auto" }}
            style={{ aspectRatio: screenSize === "mobile" ? "auto" : "16 / 9" }}
            className={screenSize + "-size"}
        >
            <Box
                fill="horizontal"
                overflow={"scroll"}
                id="scrollcontainer"
                background={{ image: "url(" + blurredBG + ")" }}
                height={{min: "fit-content"}}
                className={screenSize+"-size"}
            >
                <Box className="closemodal" margin="xsmall">
                    {/* {modalHeight} */}
                    <Button
                        plain
                        icon={<FontAwesomeIcon icon={faTimesCircle} size="2x" />}
                        onClick={() => setIsInteractiveVisible(false)}
                    />
                </Box>
                <Box
                    pad={screenSize !== "mobile" ? "large" : "xsmall"}
                    alignSelf="center"
                    height={{ min: "fit-content" }}
                    fill="horizontal"
                    className="app-container"
                >
                    {screenSize === "mobile" ? (
                        <Box className="slide first" justify={"center"} direction="row" height={"calc( .58 * 100vw)"}>
                            <Box
                                background={{ color: "dark-1", opacity: "0.9" }}
                                pad={{ horizontal: "medium", vertical: "medium" }}
                                width={{ width: "medium", max: "100%" }}
                                height="3em"
                                elevation={"small"}
                                round={"xsmall"}
                                className="content"
                                style={{ top: "calc( .57 * 100vw - 3.2em)" }}
                            >
                                <Text textAlign="center" margin={"none"} weight={"bolder"} size={"xlarge"}>
                                    &#8593; &#8593; Scroll to Begin &#8593; &#8593;
                                </Text>
                            </Box>
                        </Box>
                    ) : (
                        ""
                    )}

                    <ScrollSlide
                        align="center"
                        width="large"
                        color="none"
                        elevation="none"
                        height={1 * modalHeight + "px"}
                        topSticky={0.1 * modalHeight + "px"}
                    >
                        <Box width={screenSize !== "mobile" ? "medium" : "medium"} alignSelf="center">
                            <Image src={cpawLogo} margin={{ bottom: "large" }} className="shadow" />
                        </Box>
                        <Box
                            background={{ color: "neutral-4", opacity: "strong" }}
                            gap="small"
                            pad={"small"}
                            round="small"
                            elevation="xsmall"
                        >
                            <Text textAlign="center" size="large">
                                In the last decade, more than{" "}
                                <Text size="large" weight="bolder">
                                    100,000
                                </Text>{" "}
                                structures
                                <br />
                                have been lost to wildfire.
                            </Text>
                            <Text textAlign="center" size="xxlarge">
                                See how land use planning can help your community to survive a wildfire.
                            </Text>
                        </Box>
                        {screenSize !== "mobile" ? (
                            <Box margin={{ top: "large" }} direction="row" justify="center" className="bounce-bottom">
                                <Box background="brand" pad={{ vertical: "xsmall", horizontal: "medium" }} round="small">
                                    <Text textAlign="center" margin={"none"} weight={"bold"} size={"xxlarge"}>
                                        &#8595; &#8595; Scroll to Begin &#8595; &#8595;
                                    </Text>
                                </Box>
                            </Box>
                        ) : (
                            ""
                        )}
                    </ScrollSlide>

                    <ScrollSlide
                        align="end"
                        width="large"
                        color="brand"
                        height={3.25 * modalHeight + "px"}
                        topSticky={0.25 * modalHeight + "px"}
                    >
                        <Paragraph fill margin={{ bottom: "none" }} size="large">
                            Planning a community with wildfire in mind requires integrating risk-reduction strategies into
                            the design, layout, and development of homes and neighborhoods.
                        </Paragraph>
                    </ScrollSlide>

                    <ScrollSlide
                        align="center"
                        width="medium"
                        color="cpaw-teal"
                        height={1.5 * modalHeight + "px"}
                        topSticky={0.35 * modalHeight + "px"}
                    >
                        <Paragraph color={"white"} fill margin={{ bottom: "none" }} size="large">
                            For example, subdivisions can be designed with multiple roads in and out to ensure safe
                            evacuations,
                        </Paragraph>
                    </ScrollSlide>

                    <ScrollSlide
                        align="end"
                        width="medium"
                        color="cpaw-sage"
                        height={1.4 * modalHeight + "px"}
                        topSticky={0.6 * modalHeight + "px"}
                    >
                        <Paragraph color={"white"} fill margin={{ bottom: "none" }} size="large">
                            …open space can be managed to provide fuel breaks around communities,
                        </Paragraph>
                    </ScrollSlide>

                    <ScrollSlide
                        align="start"
                        width="medium"
                        color="cpaw-burntorange"
                        height={1.5 * modalHeight + "px"}
                        topSticky={0.7 * modalHeight + "px"}
                    >
                        <Paragraph color={"white"} fill margin={{ bottom: "none" }} size="large">
                            …and homes can be constructed with wildfire-resistant materials.
                        </Paragraph>
                    </ScrollSlide>

                    <ScrollSlide
                        align="center"
                        width="large"
                        color="neutral-4"
                        height={1.75 * modalHeight + "px"}
                        topSticky={0.25 * modalHeight + "px"}
                    >
                        <Paragraph fill margin={{ bottom: "none" }} size="xxlarge" textAlign="center">
                            Land use planning can help your community survive a wildfire.
                        </Paragraph>
                    </ScrollSlide>

                    <ScrollSlide
                        align="start"
                        width="medium"
                        color="neutral-4"
                        height={2.2 * modalHeight + "px"}
                        topSticky={0.25 * modalHeight + "px"}
                    >
                        <Paragraph fill margin={{ bottom: "none" }} size="large">
                            Here are five land use planning strategies to make your community better adapted to increasing
                            wildfire risk.
                        </Paragraph>
                        <Box direction="column" gap="small">
                            <Box background={"cpaw-teal"} pad={{ vertical: "xsmall", horizontal: "small" }} round="xsmall">
                                <Text weight={"bolder"} size="large">
                                    Community Plans
                                </Text>
                            </Box>
                            <Box background={"cpaw-orange"} pad={{ vertical: "xsmall", horizontal: "small" }} round="xsmall">
                                <Text weight={"bolder"} size="large" color={"#ffffff"}>
                                    Land Development
                                </Text>
                            </Box>
                            <Box
                                background={"cpaw-burntorange"}
                                pad={{ vertical: "xsmall", horizontal: "small" }}
                                round="xsmall"
                            >
                                <Text weight={"bolder"} size="large" color={"#ffffff"}>
                                    Building &amp; Fire Codes
                                </Text>
                            </Box>
                            <Box
                                background={"cpaw-sage"}
                                pad={{ vertical: "xsmall", horizontal: "small" }}
                                round="xsmall"
                            >
                                <Text weight={"bolder"} color={"#ffffff"} size="large">
                                    Fuels Treatment
                                </Text>
                            </Box>
                            <Box background={"cpaw-red"} pad={{ vertical: "xsmall", horizontal: "small" }} round="xsmall">
                                <Text weight={"bolder"} size="large" color={"#ffffff"}>
                                    Funding &amp; Engagement
                                </Text>
                            </Box>
                        </Box>
                    </ScrollSlide>

                    <ScrollSlide
                        align="center"
                        width="large"
                        color="none"
                        height={5 * modalHeight + "px"}
                        topSticky={0.33 * modalHeight + "px"}
                        elevation="none"
                    >
                        <Button
                            fill={"false"}
                            primary
                            color={"cpaw-teal"}
                            size="xlarge"
                            onClick={() => setIsInteractiveVisible(false)}
                            label="Click here to see what you can do!"
                        />
                    </ScrollSlide>
                </Box>
                <ScrollingMutedVideo />
            </Box>
        </Box>
    );
};

const ScrollingMutedVideo = () => {
    const refVideo = useRef(null);
    const [autoPlay, setAutoPlay] = useState(true);

    useEffect(() => {
        if (refVideo.current) {
            const vidEl = refVideo.current.videoRef.current;
            vidEl.defaultMuted = true;
            vidEl.muted = true;
        }
        document.addEventListener("load", setAutoPlay(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refVideo.current]);
    return (
        <VideoScroll
            // docs for this component available at https://github.com/nitin42/react-video-scroll
            style={{ position: "sticky" }}
            setCurrentFrame={() => {
                const scrollContainer = document.getElementById("scrollcontainer");
                const offset = scrollContainer.scrollTop;
                const modalHeight = scrollContainer.offsetHeight;
                const playbackRate = modalHeight * 1.9;
                return offset / playbackRate;
            }}
            ref={refVideo}
        >
            <video id="v0" autoPlay={autoPlay} tabIndex="0" autobuffer="autobuffer" preload="auto" playsInline>
                <source type="video/webm" src={bgVideoWebm}></source>
                <source type="video/mp4" src={bgVideo}></source>
            </video>
        </VideoScroll>
    );
};

export default ModalContent;